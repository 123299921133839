div.AboutUs p {
    text-indent: 20px;
    font-size: 14px;
    padding-bottom: 5px;
}

div.AboutUs header {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 20px;
}
		