.loader {
  height: 30px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loader--dot {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: white; 
  position: absolute;
}

.loader--dot {
  -webkit-transform-style: preserve-3d;
  background: url('http://hop.ie/balls/images/world-map-one-color.png') repeat-x;
  background-size: auto 100%;
  -webkit-animation: loader 3s ease-in-out infinite, move-map 30s infinite;
  -moz-animation: loader 3s ease-in-out infinite, move-map 30s infinite;
  -o-animation: loader 3s ease-in-out infinite, move-map 30s infinite;
  -ms-animation: loader 3s ease-in-out infinite, move-map 30s infinite;
  animation: loader 3s ease-in-out infinite, move-map 30s infinite;
}

.ball:before {
  content: "";
  position: absolute;
  background: radial-gradient(circle at 50% 120%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 70%);
  border-radius: 50%;
  bottom: 2.5%;
  left: 5%;
  opacity: 0.6;
  height: 100%;
  width: 90%;
  filter: blur(5px);
  z-index: 2;
}
.ball:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5%;
  left: 10%;
  border-radius: 50%;
  background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) 14%, rgba(255, 255, 255, 0) 24%);
  transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  filter: blur(10px);
}

.loader--dot:first-child {
  background-color: #8cc759;
  animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: #8c6daf;
  animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: #ef5d74;
  animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: #f9a74b;
  animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: #60beeb;
  animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: #fbef5a;
  animation-delay: 0s;
}
.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
}
.loader--text:after {
  content: "Loading";
  color: white;
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}



.loader--dot:before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: -40px 10px 70px 10px rgba(0,0,0,0.5) inset;
	z-index: 2;
	filter: blur(5px);
  z-index: 2;
}

.loader--dot:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-filter: blur(0);
  opacity: 0.3;
  background: radial-gradient(circle at 50% 80%, #81e8f6, #76deef 10%, #055194 66%, #062745 100%);
}

.loader--dot .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
  -webkit-transform: rotateX(90deg) translateZ(-150px);
  -moz-transform: rotateX(90deg) translateZ(-150px);
  -ms-transform: rotateX(90deg) translateZ(-150px);
  -o-transform: rotateX(90deg) translateZ(-150px);
  transform: rotateX(90deg) translateZ(-150px);
  z-index: -1;
}
/* body {
  width: 300px;
  margin: 20px auto;
  background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2) 0%, rgba(255, 255, 255, 0.5) 40%, #ffffff 100%);
  background-repeat: no-repeat;
} */

/* .loader {
  width: 20px;
  height: 250px;
  display: inline-block;
  margin: 20px;
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -ms-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
  -webkit-perspective-origin: 50% 50%;
  -moz-perspective-origin: 50% 50%;
  -ms-perspective-origin: 50% 50%;
  -o-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
} */

@-moz-keyframes move-map {
  0% {
    background-position: -849px 0; }

  100% {
    background-position: 0 0; } }

@-webkit-keyframes move-map {
  0% {
    background-position: 0 0; }
  100% {
    background-position: -849px 0; }
}


@-o-keyframes move-map {
  0% {
    background-position: -849px 0; }

  100% {
    background-position: 0 0; } }

@-ms-keyframes move-map {
  0% {
    background-position: -849px 0; }

  100% {
    background-position: 0 0; } }

@keyframes move-map {
  0% {
    background-position: -849px 0; }

  100% {
    background-position: 0 0; } }

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}