body {
  background-color: black !important;
}

html,
body {
  margin: 0;
  padding: 0;
}
 
.scene {
  display: none;
}

.scene.show {
  display: block;
}
.background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
}

.fairyTell {
  text-align: center;
  position: relative
}

#ft_vr-player {
  display: none;
  background-color: #000;
}

#ft_vr-player.shown {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3560;
}

.ftApp {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Antenna-Regular, '.SFNSDisplay-Regular', 'Helvetica Neue', Helvetica, Arial, system, -apple-system, sans-serif;
  color: #fff;
  background-color: #0C1218;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

.ftApp * {
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.ftApp *,
.ftApp *:after,
.ftApp *::before {
    box-sizing: border-box;
}

.ftApp div {
  outline: 0 !important;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.ftApp_AppContainer {
  width: 100%;
  height: 100%;
}

.ft_vr_container a-scene {
  position: static !important
}

a-scene {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

button.exitBtn {
  cursor: pointer;
}

.ftApp button {
  margin: 0;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
}

.exitBtn {
  outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 48px;
    height: 48px;
    opacity: 0.8;
    background-color: #0678BC;
    -webkit-transition: opacity 0.3s cubic-bezier(0.550,0.085,0.680,0.530);
    transition: opacity 0.3s cubic-bezier(0.550,0.085,0.680,0.530);
    overflow: hidden;
}

#splash {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 200px;
  height: 200px;

  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  animation: spin 1s infinite linear;
}

.detailsContainer {
  background: white;
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 16px;
}
details {
	 font-size: 1rem;
	 box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
	 background: #fff;
	 border-radius: 16px;
	 position: relative;
	 width: 500px;
}
 details .summary-title {
	 user-select: none;
}
 details:hover {
	 cursor: pointer;
}
 details .summary-content {
	  border-top: 1px solid #e2e8f0;
	  cursor: default;
	  padding: 1em;
	  font-weight: 300;
    line-height: 1.5;
    margin: 0 auto;
}
 details summary {
	 padding: 1em;
}
 details summary:focus {
	 outline: none;
}
 details summary:hover .summary-chevron-up svg {
	 opacity: 1;
}
 details .summary-chevron-up svg {
	 opacity: 0.5;
}
 details .summary-chevron-up, details .summary-chevron-down {
	 pointer-events: none;
	 position: absolute;
	 top: 0.75em;
	 right: 1em;
	 background: #fff;
}
 details .summary-chevron-up svg, details .summary-chevron-down svg {
	 display: block;
}
 details summary::-webkit-details-marker {
	 display: none;
}

/* Begin Experience */
.btn-flip {
   cursor: pointer;
	 opacity: 1;
	 outline: 0;
	 color: #fff;
	 line-height: 40px;
	 position: relative;
	 text-align: center;
	 letter-spacing: 1px;
	 display: inline-block;
	 text-decoration: none;
	 font-family: 'Open Sans';
	 text-transform: uppercase;
}
 .btn-flip:hover:after {
	 opacity: 1;
	 transform: translateY(0) rotateX(0);
}
 .btn-flip:hover:before {
	 opacity: 0;
	 transform: translateY(50%) rotateX(90deg);
}
 .btn-flip:after {
	 top: 0;
	 left: 0;
	 opacity: 0;
	 width: 100%;
	 color: #323237;
	 display: block;
	 transition: 0.5s;
	 position: absolute;
	 background: #adadaf;
	 content: attr(data-back);
	 transform: translateY(-50%) rotateX(90deg);
}
 .btn-flip:before {
	 top: 0;
	 left: 0;
	 opacity: 1;
	 color: #adadaf;
	 display: block;
	 padding: 0 30px;
	 line-height: 40px;
	 transition: 0.5s;
	 position: relative;
	 background: #323237;
	 content: attr(data-front);
	 transform: translateY(0) rotateX(0);
}
 

.summary-content img {
  display: block;
}

/* loading 
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -65px;
  margin-left: -65px;
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
*/
/* COLOR SPHERE */
 
/* custom */
@media (min-width: 600px) {
  .App  div[class^='makeStyles-drawerClose'],
  .App  div[class*=' makeStyles-drawerClose'] {
      width: 65px;
  }
}

h5
{
  margin:0px;
  font-size:1.4em;
  font-weight:700;
}

p
{
  font-size:12px;
}

.center
{
  height:100vh;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* End Non-Essential  */

.property-card
{
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
  box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff; */
}
/* ^-- The margin bottom is necessary for the drop shadow otherwise it gets clipped in certain cases. */

/* Top Half of card, image. */

.property-image
{
  height:12em;
  width:24em;
  padding:1em 2em;
  position:Absolute;
  top:0px;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-image:url('https://cdn.photographylife.com/wp-content/uploads/2017/01/What-is-landscape-photography.jpg');
  background-size:cover;
  background-repeat:no-repeat;
}

/* Bottom Card Section */

.property-description
{
  background-color: #FAFAFC;
  height:16em;
  width:24em;
  position:absolute;
  bottom:0em;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.5em 1em;
  text-align:center;
}

/* Social Icons */

.property-social-icons
{
  width:1em;
  height:1em;
  background-color:black;
  position:absolute;
  bottom:1em;
  left:1em;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Property Cards Hover States */

.property-card:hover .property-description
{
  height:0em;
  padding:0px 1em;
}
.property-card:hover .property-image
{
  height:28em;
}

.property-card:hover .property-social-icons
{
  background-color:white;
}

.property-card:hover .property-social-icons:hover
{
  background-color:blue;
  cursor:pointer;
}


/* Optional*/

.property-image-title
{
text-align:center;
position:Relative;
top:30%;
opacity:0;
transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
color:black;
font-size:1.2em;
}

.property-card:hover .property-image-title
{
opacity:1;
}

/* Wrapper */
.icon-button {
	background-color: white;
	border-radius: 3.6rem;
	cursor: pointer;
	display: inline-block;
	font-size: 2.0rem;
	height: 3.6rem;
	line-height: 3.6rem;
	margin: 0 5px;
	position: relative;
	text-align: center;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	width: 3.6rem;
}

/* Circle */
.icon-button span {
	border-radius: 0;
	display: block;
	height: 0;
	left: 50%;
	margin: 0;
	position: absolute;
	top: 50%;
	-webkit-transition: all 0.3s;
	   -moz-transition: all 0.3s;
	     -o-transition: all 0.3s;
	        transition: all 0.3s;
	width: 0;
}
.icon-button:hover span {
	width: 3.6rem;
	height: 3.6rem;
	border-radius: 3.6rem;
	margin: -1.8rem;
}
.twitter span {
	background-color: #4099ff;
}
.facebook span {
	background-color: #3B5998;
}
.google-plus span {
	background-color: #db5a3c;
}

/* Icons */
.icon-button i {
	background: none;
	color: white;
	height: 3.6rem;
	left: 0;
	line-height: 3.6rem;
	position: absolute;
	top: 0;
	-webkit-transition: all 0.3s;
	   -moz-transition: all 0.3s;
	     -o-transition: all 0.3s;
	        transition: all 0.3s;
	width: 3.6rem;
	z-index: 10;
}
.icon-button .icon-twitter {
	color: #4099ff;
}
.icon-button .icon-facebook {
	color: #3B5998;
}
.icon-button .icon-google-plus {
	color: #db5a3c;
}
.icon-button:hover .icon-twitter,
.icon-button:hover .icon-facebook,
.icon-button:hover .icon-google-plus {
	color: white;
}

.property-card ul {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.property-card li {
  display: inline-block;
}


/* close button */
.close-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 7px;
  padding-top: 7px;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  animation: cssAnimation 0s 3s forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  to   { visibility: visible; }
}

.close-button {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
}
.close-button > div {
  position: relative;
}
.close-button-block {
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}
.close-button-block:before, .close-button-block:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(55% - 4px);
  display: block;
  width: 4px;
  height: 25px;
  transform-origin: bottom center;
  background: black;
  transition: all ease-out 280ms;
}
.close-button-block:last-of-type {
  transform: rotate(180deg);
}
.close-button .in .close-button-block:before {
  transition-delay: 280ms;
  transform: translateX(20px) translateY(-20px) rotate(45deg);
}
.close-button .in .close-button-block:after {
  transition-delay: 280ms;
  transform: translateX(-22px) translateY(-22px) rotate(-45deg);
}
.close-button .out {
  position: absolute;
  top: 0;
  left: 0;
}
.close-button .out .close-button-block:before {
  transform: translateX(-5px) translateY(5px) rotate(45deg);
}
.close-button .out .close-button-block:after {
  transform: translateX(5px) translateY(5px) rotate(-45deg);
}
.close-button:hover .in .close-button-block:before {
  transform: translateX(-5px) translateY(5px) rotate(45deg);
}
.close-button:hover .in .close-button-block:after {
  transform: translateX(5px) translateY(5px) rotate(-45deg);
}
.close-button:hover .out .close-button-block:before {
  transform: translateX(-20px) translateY(20px) rotate(45deg);
}
.close-button:hover .out .close-button-block:after {
  transform: translateX(20px) translateY(20px) rotate(-45deg);
}

/* Bio */

body:before {
  height: 0px;
  padding: 0px;
  border: 130em solid #313440;
  position: absolute;
  left: 50%;
  top: 100%;
  z-index: 2;
  display: block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, borderRadius 0.2s 2.3s linear forwards;
  animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, borderRadius 0.2s 2.3s linear forwards;
}

h1,
h2 {
  font-weight: 500;
  margin: 0px 0px 5px 0px;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 16px;
}

p {
  margin: 0px;
}

:focus {
    outline: none !important;
}