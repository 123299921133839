@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: black !important;
}

html,
body {
  margin: 0;
  padding: 0;
}
 
.scene {
  display: none;
}

.scene.show {
  display: block;
}
.background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
}

.fairyTell {
  text-align: center;
  position: relative
}

#ft_vr-player {
  display: none;
  background-color: #000;
}

#ft_vr-player.shown {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3560;
}

.ftApp {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Antenna-Regular, '.SFNSDisplay-Regular', 'Helvetica Neue', Helvetica, Arial, system, -apple-system, sans-serif;
  color: #fff;
  background-color: #0C1218;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

.ftApp * {
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.ftApp *,
.ftApp *:after,
.ftApp *::before {
    box-sizing: border-box;
}

.ftApp div {
  outline: 0 !important;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  padding: 0;
}
.line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.ftApp_AppContainer {
  width: 100%;
  height: 100%;
}

.ft_vr_container a-scene {
  position: static !important
}

a-scene {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

button.exitBtn {
  cursor: pointer;
}

.ftApp button {
  margin: 0;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
}

.exitBtn {
  outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 48px;
    height: 48px;
    opacity: 0.8;
    background-color: #0678BC;
    transition: opacity 0.3s cubic-bezier(0.550,0.085,0.680,0.530);
    overflow: hidden;
}

#splash {
  position: absolute;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 200px;
  height: 200px;

  margin: auto;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loading {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}

.detailsContainer {
  background: white;
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 16px;
}
details {
	 font-size: 1rem;
	 box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
	 background: #fff;
	 border-radius: 16px;
	 position: relative;
	 width: 500px;
}
 details .summary-title {
	 -webkit-user-select: none;
	     -ms-user-select: none;
	         user-select: none;
}
 details:hover {
	 cursor: pointer;
}
 details .summary-content {
	  border-top: 1px solid #e2e8f0;
	  cursor: default;
	  padding: 1em;
	  font-weight: 300;
    line-height: 1.5;
    margin: 0 auto;
}
 details summary {
	 padding: 1em;
}
 details summary:focus {
	 outline: none;
}
 details summary:hover .summary-chevron-up svg {
	 opacity: 1;
}
 details .summary-chevron-up svg {
	 opacity: 0.5;
}
 details .summary-chevron-up, details .summary-chevron-down {
	 pointer-events: none;
	 position: absolute;
	 top: 0.75em;
	 right: 1em;
	 background: #fff;
}
 details .summary-chevron-up svg, details .summary-chevron-down svg {
	 display: block;
}
 details summary::-webkit-details-marker {
	 display: none;
}

/* Begin Experience */
.btn-flip {
   cursor: pointer;
	 opacity: 1;
	 outline: 0;
	 color: #fff;
	 line-height: 40px;
	 position: relative;
	 text-align: center;
	 letter-spacing: 1px;
	 display: inline-block;
	 text-decoration: none;
	 font-family: 'Open Sans';
	 text-transform: uppercase;
}
 .btn-flip:hover:after {
	 opacity: 1;
	 -webkit-transform: translateY(0) rotateX(0);
	         transform: translateY(0) rotateX(0);
}
 .btn-flip:hover:before {
	 opacity: 0;
	 -webkit-transform: translateY(50%) rotateX(90deg);
	         transform: translateY(50%) rotateX(90deg);
}
 .btn-flip:after {
	 top: 0;
	 left: 0;
	 opacity: 0;
	 width: 100%;
	 color: #323237;
	 display: block;
	 transition: 0.5s;
	 position: absolute;
	 background: #adadaf;
	 content: attr(data-back);
	 -webkit-transform: translateY(-50%) rotateX(90deg);
	         transform: translateY(-50%) rotateX(90deg);
}
 .btn-flip:before {
	 top: 0;
	 left: 0;
	 opacity: 1;
	 color: #adadaf;
	 display: block;
	 padding: 0 30px;
	 line-height: 40px;
	 transition: 0.5s;
	 position: relative;
	 background: #323237;
	 content: attr(data-front);
	 -webkit-transform: translateY(0) rotateX(0);
	         transform: translateY(0) rotateX(0);
}
 

.summary-content img {
  display: block;
}

/* loading 
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -65px;
  margin-left: -65px;
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
*/
/* COLOR SPHERE */
 
/* custom */
@media (min-width: 600px) {
  .App  div[class^='makeStyles-drawerClose'],
  .App  div[class*=' makeStyles-drawerClose'] {
      width: 65px;
  }
}

h5
{
  margin:0px;
  font-size:1.4em;
  font-weight:700;
}

p
{
  font-size:12px;
}

.center
{
  height:100vh;
  width:100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

/* End Non-Essential  */

.property-card
{
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
  box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff; */
}
/* ^-- The margin bottom is necessary for the drop shadow otherwise it gets clipped in certain cases. */

/* Top Half of card, image. */

.property-image
{
  height:12em;
  width:24em;
  padding:1em 2em;
  position:Absolute;
  top:0px;
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-image:url('https://cdn.photographylife.com/wp-content/uploads/2017/01/What-is-landscape-photography.jpg');
  background-size:cover;
  background-repeat:no-repeat;
}

/* Bottom Card Section */

.property-description
{
  background-color: #FAFAFC;
  height:16em;
  width:24em;
  position:absolute;
  bottom:0em;
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.5em 1em;
  text-align:center;
}

/* Social Icons */

.property-social-icons
{
  width:1em;
  height:1em;
  background-color:black;
  position:absolute;
  bottom:1em;
  left:1em;
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Property Cards Hover States */

.property-card:hover .property-description
{
  height:0em;
  padding:0px 1em;
}
.property-card:hover .property-image
{
  height:28em;
}

.property-card:hover .property-social-icons
{
  background-color:white;
}

.property-card:hover .property-social-icons:hover
{
  background-color:blue;
  cursor:pointer;
}


/* Optional*/

.property-image-title
{
text-align:center;
position:Relative;
top:30%;
opacity:0;
transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
color:black;
font-size:1.2em;
}

.property-card:hover .property-image-title
{
opacity:1;
}

/* Wrapper */
.icon-button {
	background-color: white;
	border-radius: 3.6rem;
	cursor: pointer;
	display: inline-block;
	font-size: 2.0rem;
	height: 3.6rem;
	line-height: 3.6rem;
	margin: 0 5px;
	position: relative;
	text-align: center;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	width: 3.6rem;
}

/* Circle */
.icon-button span {
	border-radius: 0;
	display: block;
	height: 0;
	left: 50%;
	margin: 0;
	position: absolute;
	top: 50%;
	transition: all 0.3s;
	width: 0;
}
.icon-button:hover span {
	width: 3.6rem;
	height: 3.6rem;
	border-radius: 3.6rem;
	margin: -1.8rem;
}
.twitter span {
	background-color: #4099ff;
}
.facebook span {
	background-color: #3B5998;
}
.google-plus span {
	background-color: #db5a3c;
}

/* Icons */
.icon-button i {
	background: none;
	color: white;
	height: 3.6rem;
	left: 0;
	line-height: 3.6rem;
	position: absolute;
	top: 0;
	transition: all 0.3s;
	width: 3.6rem;
	z-index: 10;
}
.icon-button .icon-twitter {
	color: #4099ff;
}
.icon-button .icon-facebook {
	color: #3B5998;
}
.icon-button .icon-google-plus {
	color: #db5a3c;
}
.icon-button:hover .icon-twitter,
.icon-button:hover .icon-facebook,
.icon-button:hover .icon-google-plus {
	color: white;
}

.property-card ul {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.property-card li {
  display: inline-block;
}


/* close button */
.close-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-right: 7px;
  padding-top: 7px;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-animation: cssAnimation 0s 3s forwards;
          animation: cssAnimation 0s 3s forwards;
  visibility: hidden;
}

@-webkit-keyframes cssAnimation {
  to   { visibility: visible; }
}

@keyframes cssAnimation {
  to   { visibility: visible; }
}

.close-button {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
}
.close-button > div {
  position: relative;
}
.close-button-block {
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}
.close-button-block:before, .close-button-block:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(55% - 4px);
  display: block;
  width: 4px;
  height: 25px;
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  background: black;
  transition: all ease-out 280ms;
}
.close-button-block:last-of-type {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.close-button .in .close-button-block:before {
  transition-delay: 280ms;
  -webkit-transform: translateX(20px) translateY(-20px) rotate(45deg);
          transform: translateX(20px) translateY(-20px) rotate(45deg);
}
.close-button .in .close-button-block:after {
  transition-delay: 280ms;
  -webkit-transform: translateX(-22px) translateY(-22px) rotate(-45deg);
          transform: translateX(-22px) translateY(-22px) rotate(-45deg);
}
.close-button .out {
  position: absolute;
  top: 0;
  left: 0;
}
.close-button .out .close-button-block:before {
  -webkit-transform: translateX(-5px) translateY(5px) rotate(45deg);
          transform: translateX(-5px) translateY(5px) rotate(45deg);
}
.close-button .out .close-button-block:after {
  -webkit-transform: translateX(5px) translateY(5px) rotate(-45deg);
          transform: translateX(5px) translateY(5px) rotate(-45deg);
}
.close-button:hover .in .close-button-block:before {
  -webkit-transform: translateX(-5px) translateY(5px) rotate(45deg);
          transform: translateX(-5px) translateY(5px) rotate(45deg);
}
.close-button:hover .in .close-button-block:after {
  -webkit-transform: translateX(5px) translateY(5px) rotate(-45deg);
          transform: translateX(5px) translateY(5px) rotate(-45deg);
}
.close-button:hover .out .close-button-block:before {
  -webkit-transform: translateX(-20px) translateY(20px) rotate(45deg);
          transform: translateX(-20px) translateY(20px) rotate(45deg);
}
.close-button:hover .out .close-button-block:after {
  -webkit-transform: translateX(20px) translateY(20px) rotate(-45deg);
          transform: translateX(20px) translateY(20px) rotate(-45deg);
}

/* Bio */

body:before {
  height: 0px;
  padding: 0px;
  border: 130em solid #313440;
  position: absolute;
  left: 50%;
  top: 100%;
  z-index: 2;
  display: block;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, borderRadius 0.2s 2.3s linear forwards;
  animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, borderRadius 0.2s 2.3s linear forwards;
}

h1,
h2 {
  font-weight: 500;
  margin: 0px 0px 5px 0px;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 16px;
}

p {
  margin: 0px;
}

:focus {
    outline: none !important;
}
.summary-content {
  font-family: 'Open Sans', Arial, sans-serif;
  position: relative;
  margin: 10px;
  min-width: 230px;
  max-width: 400px;
  width: 100%;
  color: #000000;
  text-align: center;
  line-height: 1.4em;
  font-size: 14px;
	box-shadow: none !important;
}

.summary-content * {
  box-sizing: border-box;
}

.summary-content .profile-image {
  display: inline-block;
  width: 50%;
  z-index: 1;
  position: relative;
  padding: 0;
  border: 2px solid #e8b563;
}

.summary-content .profile-image img {
  max-width: 100%;
  vertical-align: top;
}

.summary-content figcaption {
  width: 100%;
  background-color: #F2F2F2;
  color: #555;
  padding: 125px 25px 25px;
  margin-top: -120px;
  display: inline-block;
}

.summary-content h3,
.summary-content h4,
.summary-content p {
  margin: 0 0 5px;
}

.summary-content h3 {
  font-weight: 600;
  font-size: 1.3em;
  font-family: 'Playfair Display', Arial, sans-serif;
}

.summary-content h4 {
  color: #8c8c8c;
  font-weight: 400;
  letter-spacing: 2px;
}

.summary-content p {
  font-size: 0.9em;
  letter-spacing: 1px;
  opacity: 0.9;
}

.summary-content .icons {
  text-align: center;
  width: 100%;
}

.summary-content i {
  padding: 10px 2px;
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  color: #e8b563;
  opacity: 0.75;
}

.summary-content i:hover {
  opacity: 1;
  transition: all 0.35s ease;
}

.profile-card {
  background: #FFB300;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  overflow: hidden;
  opacity: 0;
  margin-top: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 16px !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
  -webkit-animation: init 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, moveDown 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards, moveUp 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards, materia 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards;
  animation: init 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, moveDown 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards, moveUp 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards, materia 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards;
}

.profile-card header {
  width: 179px;
  height: 280px;
  padding: 40px 20px 30px 20px;
  display: inline-block;
  float: left;
  border-right: 2px dashed #EEEEEE;
  background: #FFFFFF;
  color: #000000;
  margin-top: 50px;
  opacity: 0;
  text-align: center;
  -webkit-animation: moveIn 1s 3.1s ease forwards;
  animation: moveIn 1s 3.1s ease forwards;
}

.profile-card header h1 {
  color: #FF5722;
}

.profile-card header a {
  display: inline-block;
  text-align: center;
  position: relative;
  margin: 25px 30px;
}

/* .profile-card header a:after {
  position: absolute;
  content: "";
  bottom: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
  border: 4px solid #FFFFFF;
  -webkit-transform: scale(0);
  transform: scale(0);
  background: -webkit-linear-gradient(top, #2196F3 0%, #2196F3 50%, #FFC107 50%, #FFC107 100%);
  background: linear-gradient(#2196F3 0%, #2196F3 50%, #FFC107 50%, #FFC107 100%);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-animation: scaleIn 0.3s 3.5s ease forwards;
  animation: scaleIn 0.3s 3.5s ease forwards;
} */

.profile-card header img {
  width: 120px;
  max-width: 100%;
  border-radius: 50%;
  transition: box-shadow 0.3s ease;
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.06);
}

.profile-card header img:hover {
  box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.profile-card .profile-bio {
  width: 175px;
  height: 180px;
  display: inline-block;
  float: right;
  padding: 50px 20px 30px 20px;
  background: #FFFFFF;
  color: #333333;
  margin-top: 50px;
  text-align: center;
  opacity: 0;
  -webkit-animation: moveIn 1s 3.1s ease forwards;
  animation: moveIn 1s 3.1s ease forwards;
}

.profile-social-links {
  width: 218px;
  display: inline-block;
  float: right;
  margin: 0px;
  padding: 15px 20px;
  background: #FFFFFF;
  margin-top: 50px;
  text-align: center;
  opacity: 0;
  box-sizing: border-box;
  -webkit-animation: moveIn 1s 3.1s ease forwards;
  animation: moveIn 1s 3.1s ease forwards;
}

.profile-social-links li {
  list-style: none;
  margin: -5px 0px 0px 0px;
  padding: 0px;
  float: left;
  width: 25%;
  text-align: center;
}

.profile-social-links li a {
  display: inline-block;
  color: red;
  width: 24px;
  height: 24px;
  padding: 6px;
  position: relative;
  overflow: hidden!important;
  border-radius: 50%;
}

.profile-social-links li a i {
  position: relative;
  z-index: 1;
}

.profile-social-links li a img,
.profile-social-links li a svg {
  width: 24px;
}

@-webkit-keyframes init {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    width: 56px;
    height: 56px;
    margin-top: 0px;
    opacity: 1;
  }
}

@keyframes init {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    width: 56px;
    height: 56px;
    margin-top: 0px;
    opacity: 1;
  }
}

@-webkit-keyframes puff {
  0% {
    top: 100%;
    height: 0px;
    padding: 0px;
  }
  100% {
    top: 50%;
    height: 100%;
    padding: 0px 100%;
  }
}

@keyframes puff {
  0% {
    top: 100%;
    height: 0px;
    padding: 0px;
  }
  100% {
    top: 50%;
    height: 100%;
    padding: 0px 100%;
  }
}

@-webkit-keyframes borderRadius {
  0% {
    -webkit-border-radius: 50%;
  }
  100% {
    -webkit-border-radius: 0px;
  }
}

@keyframes borderRadius {
  0% {
    -webkit-border-radius: 50%;
  }
  100% {
    border-radius: 0px;
  }
}

@-webkit-keyframes moveDown {
  0% {
    top: 50%;
  }
  50% {
    top: 40%;
  }
  100% {
    top: 100%;
  }
}

@keyframes moveDown {
  0% {
    top: 50%;
  }
  50% {
    top: 40%;
  }
  100% {
    top: 100%;
  }
}

@-webkit-keyframes moveUp {
  0% {
    background: #FFB300;
    top: 100%;
  }
  50% {
    top: 40%;
  }
  100% {
    top: 50%;
    background: #E0E0E0;
  }
}

@keyframes moveUp {
  0% {
    background: #FFB300;
    top: 100%;
  }
  50% {
    top: 40%;
  }
  100% {
    top: 50%;
    background: #E0E0E0;
  }
}

@-webkit-keyframes materia {
  0% {
    background: #E0E0E0;
  }
  50% {
    -webkit-border-radius: 4px;
  }
  100% {
    width: 440px;
    height: 280px;
    background: #FFFFFF;
    -webkit-border-radius: 4px;
  }
}

@keyframes materia {
  0% {
    background: #E0E0E0;
  }
  50% {
    border-radius: 4px;
  }
  100% {
    width: 440px;
    height: 280px;
    background: #FFFFFF;
    border-radius: 4px;
  }
}

@-webkit-keyframes moveIn {
  0% {
    margin-top: 50px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-top: -20px;
  }
}

@keyframes moveIn {
  0% {
    margin-top: 50px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-top: -20px;
  }
}

@-webkit-keyframes scaleIn {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes scaleIn {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
            transform: scale3d(0, 0, 0);
  }
  50%,
  100% {
    -webkit-transform: scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
            transform: scale3d(0, 0, 0);
  }
  50%,
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
  }
}

@media screen and (min-aspect-ratio: 4/3) {
  body {
    background-size: cover;
  }
  body:before {
    width: 0px;
  }
  @-webkit-keyframes puff {
    0% {
      top: 100%;
      width: 0px;
      padding-bottom: 0px;
    }
    100% {
      top: 50%;
      width: 100%;
      padding-bottom: 100%;
    }
  }
  @keyframes puff {
    0% {
      top: 100%;
      width: 0px;
      padding-bottom: 0px;
    }
    100% {
      top: 50%;
      width: 100%;
      padding-bottom: 100%;
    }
  }
}

@media screen and (min-height: 480px) {
  .profile-card header {
    width: auto;
    height: auto;
    display: block;
    float: none;
    border-right: none;
  }
  .profile-card .profile-bio {
    width: auto;
    height: auto;
    padding: 0px 20px 10px 20px;
    display: block;
    float: none;
  }
  .profile-social-links {
    width: 100%;
    display: block;
    float: none;
  }
  @-webkit-keyframes materia {
    0% {
      background: #E0E0E0;
    }
    50% {
      -webkit-border-radius: 4px;
    }
    100% {
      width: 280px;
      height: 440px;
      background: #FFFFFF;
      -webkit-border-radius: 4px;
    }
  }
  @keyframes materia {
    0% {
      background: #E0E0E0;
    }
    50% {
      border-radius: 4px;
    }
    100% {
      width: 280px;
      height: 440px;
      background: #FFFFFF;
      border-radius: 4px;
    }
  }
}


/* Social Media */
ul.smWrapper {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: rotate(-4deg) skew(25deg) translate(-5%, 125%);
          transform: rotate(-4deg) skew(25deg) translate(-5%, 125%);
}

ul.smWrapper li {
  position: relative;
  list-style: none;
  width: 40px;
  height: 40px;
  margin: 0 10px;
}

ul.smWrapper li:before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  background: #ad9a4d;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: skewX(-43deg);
          transform: skewX(-43deg);
}
ul.smWrapper li:after {
  content: '';
  position: absolute;
  top: 0;
  left: -9px;
  width: 9px;
  height: 100%;
  background: #ad9a4d;
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: skewY(-47deg);
          transform: skewY(-47deg);
}

ul.smWrapper li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: #ffcc00;
  color: rgba(255,255,255,0.6);
  font-size: 30px !important;
  transition: 0.2s;
}

ul.smWrapper li:hover span {
  z-index: 1000;
  transition: 0.5s;
  color: #fff;
  box-shadow: -1px 1px 1px rgba(0,0,0,0.05);
}

ul.smWrapper li:hover span:nth-child(5) {
  -webkit-transform: translate(40px, -40px);
          transform: translate(40px, -40px);
  opacity: 1;
}
ul.smWrapper li:hover span:nth-child(4) {
  -webkit-transform: translate(30px, -30px);
          transform: translate(30px, -30px);
  opacity: 0.8;
}
ul.smWrapper li:hover span:nth-child(3) {
  -webkit-transform: translate(20px, -20px);
          transform: translate(20px, -20px);
  opacity: 0.6;
}
ul.smWrapper li:hover span:nth-child(2) {
  -webkit-transform: translate(10px, -10px);
          transform: translate(10px, -10px);
  opacity: 0.4;
}
ul.smWrapper li:hover span:nth-child(1) {
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
  opacity: 0.2;
}

ul.smWrapper li:nth-child(1):hover span {
  background: #3b5999;
}
ul.smWrapper li:nth-child(2):hover span {
  background: #e4405f;
}
ul.smWrapper li:nth-child(3):hover span {
  background: #55acee;
}
ul[class^='makeStyles-gridList'],
ul[class*=' makeStyles-gridList'] {
    width: 100%;
}

li.MuiGridListTile-root {
    height: inherit !important;
}
		
div.AboutUs p {
    text-indent: 20px;
    font-size: 14px;
    padding-bottom: 5px;
}

div.AboutUs header {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 20px;
}
		
.loader {
  height: 30px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loader-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
}

.loader--dot {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: white; 
  position: absolute;
}

.loader--dot {
  -webkit-transform-style: preserve-3d;
  background: url('http://hop.ie/balls/images/world-map-one-color.png') repeat-x;
  background-size: auto 100%;
  -webkit-animation: loader 3s ease-in-out infinite, move-map 30s infinite;
  animation: loader 3s ease-in-out infinite, move-map 30s infinite;
}

.ball:before {
  content: "";
  position: absolute;
  background: radial-gradient(circle at 50% 120%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 70%);
  border-radius: 50%;
  bottom: 2.5%;
  left: 5%;
  opacity: 0.6;
  height: 100%;
  width: 90%;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  z-index: 2;
}
.ball:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5%;
  left: 10%;
  border-radius: 50%;
  background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) 14%, rgba(255, 255, 255, 0) 24%);
  -webkit-transform: translateX(-80px) translateY(-90px) skewX(-20deg);
          transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  -webkit-filter: blur(10px);
          filter: blur(10px);
}

.loader--dot:first-child {
  background-color: #8cc759;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: #8c6daf;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: #ef5d74;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: #f9a74b;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: #60beeb;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: #fbef5a;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
}
.loader--text:after {
  content: "Loading";
  color: white;
  font-weight: bold;
  -webkit-animation-name: loading-text;
          animation-name: loading-text;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes loader {
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  45% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  65% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  95% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes loader {
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  45% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  65% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  95% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}



.loader--dot:before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: -40px 10px 70px 10px rgba(0,0,0,0.5) inset;
	z-index: 2;
	-webkit-filter: blur(5px);
	        filter: blur(5px);
  z-index: 2;
}

.loader--dot:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-filter: blur(0);
  opacity: 0.3;
  background: radial-gradient(circle at 50% 80%, #81e8f6, #76deef 10%, #055194 66%, #062745 100%);
}

.loader--dot .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
  -webkit-transform: rotateX(90deg) translateZ(-150px);
  transform: rotateX(90deg) translateZ(-150px);
  z-index: -1;
}
/* body {
  width: 300px;
  margin: 20px auto;
  background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2) 0%, rgba(255, 255, 255, 0.5) 40%, #ffffff 100%);
  background-repeat: no-repeat;
} */

/* .loader {
  width: 20px;
  height: 250px;
  display: inline-block;
  margin: 20px;
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -ms-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
  -webkit-perspective-origin: 50% 50%;
  -moz-perspective-origin: 50% 50%;
  -ms-perspective-origin: 50% 50%;
  -o-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
} */

@-webkit-keyframes move-map {
  0% {
    background-position: 0 0; }
  100% {
    background-position: -849px 0; }
}

@keyframes move-map {
  0% {
    background-position: -849px 0; }

  100% {
    background-position: 0 0; } }

.fade-enter {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}
.exitBtn {
  outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 48px;
    height: 48px;
    opacity: 0.8;
    background-color: #0678BC;
    transition: opacity 0.3s cubic-bezier(0.550,0.085,0.680,0.530);
    overflow: hidden;
}
