.exitBtn {
  outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 48px;
    height: 48px;
    opacity: 0.8;
    background-color: #0678BC;
    -webkit-transition: opacity 0.3s cubic-bezier(0.550,0.085,0.680,0.530);
    transition: opacity 0.3s cubic-bezier(0.550,0.085,0.680,0.530);
    overflow: hidden;
}